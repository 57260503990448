<template>
    <section>
        <div class="row mx-0 justify-center py-4">
            <div class="col-10">
                <div class="row mx-0 border-bottom">
                    <router-link
                    class="col text-center"
                    :class="$route.name == 'admin.estadisticas.movimientos-productos.retiros-cedis' ? 'border-b-red text-general-red' : 'text-general2'"
                    :to="{name: 'admin.estadisticas.movimientos-productos.retiros-cedis'}"
                    >
                        Retiros CEDIS
                    </router-link>
                    <router-link
                    class="col text-center"
                    :class="$route.name == 'admin.estadisticas.movimientos-productos.traslados-cedis' ? 'border-b-red text-general-red' : 'text-general2'"
                    :to="{name: 'admin.estadisticas.movimientos-productos.traslados-cedis'}"
                    >
                        Traslados CEDIS
                    </router-link>
                    <router-link
                    class="col text-center"
                    :class="$route.name == 'admin.estadisticas.movimientos-productos.retiros-leecheros' ? 'border-b-red text-general-red' : 'text-general2'"
                    :to="{name: 'admin.estadisticas.movimientos-productos.retiros-leecheros'}"
                    >
                        Retiros {{ $config.vendedor }}
                    </router-link>
                    <router-link
                    class="col text-center"
                    :class="$route.name == 'admin.estadisticas.movimientos-productos.devoluciones-leecheros' ? 'border-b-red text-general-red' : 'text-general2'"
                    :to="{name: 'admin.estadisticas.movimientos-productos.devoluciones-leecheros'}"
                    >
                        Devolucion {{ $config.vendedor }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 216px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style scoped>
a{text-decoration: none !important ;}
a:hover{
    color: var(--color-general) !important;
}
</style>
